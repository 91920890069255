import React, { useState } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'gatsby';

import { Date, Image, ImageWrapper, Title, Wrapper } from './News.styled';
import Placeholder from './placeholder.png';

dayjs.extend(advancedFormat);

interface Props {
  href: string;
  title: string;
  description?: string;
  date: string;
  displayImage?: string;
}

/**
 * Display the news
 *
 * @param {string} href The link of the news page
 * @param {string} title
 * @param {string} date
 * @param {string} displayImage URL of the display image
 * @param {boolean} white Flag to dictate whether or not should the text be white or black
 */
const News: React.FunctionComponent<Props> = ({
  href,
  title,
  date,
  displayImage,
}: Props) => {
  const [loaded, setLoaded] = useState(false);
  const dateFormat = dayjs(date).format('Do MMMM YYYY');

  function onLoad() {
    setLoaded(true);
  }

  return (
    <Link to={href} style={{ display: 'block', textDecoration: 'none' }}>
      <Wrapper data-cy="news-container">
        <div>
          <ImageWrapper>
            {!loaded ? (
              <Image
                className="news-image"
                src={Placeholder}
                alt="News display"
                style={{ zIndex: 1 }}
              />
            ) : null}
            <Image
              className="news-image"
              src={displayImage || Placeholder}
              alt="News display"
              onLoad={onLoad}
              style={{ zIndex: 2 }}
            />
          </ImageWrapper>
          <Title>{title}</Title>
        </div>
        <Date>{dateFormat}</Date>
      </Wrapper>
    </Link>
  );
};

export default News;

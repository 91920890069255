import styled from 'styled-components';

export const Title = styled.div`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 32px;
  margin-top: 0.5rem;
  &:hover {
    cursor: default;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 256px;

  overflow: hidden;
  position: relative;

  @media only screen and (min-width: 768px) {
    height: 396px;
  }

  @media only screen and (min-width: 1024px) {
    height: 256px;
  }
`;

export const Image = styled.img`
  height: 100%;
  max-height: 100%;

  width: 100%;
  max-width: 100%;

  position: absolute;
  left: 0;
  top: 0;

  -o-object-fit: cover;
  object-fit: cover;

  transition: all 1s 0.05s ease;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: ${({ theme }) => theme.fontSizeSmall};
  &:hover {
    cursor: default;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  height: 100%;
  margin-bottom: 1rem;

  transition: all 0.35s ease;

  .news-image {
    height: 100%;
    max-height: 100%;

    width: 100%;
    max-width: 100%;

    position: absolute;
    left: 0;
    top: 0;

    transition: all 1s 0.05s ease;
  }

  &:hover {
    transform: translateY(-16px);
    .news-image {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`;

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import { Date, ImageWrapper, Title, Wrapper } from './News.styled';

dayjs.extend(advancedFormat);

interface Props {
  href: string;
  title: string;
  date: string;
  displayImage: IGatsbyImageData;
}

/**
 * Display the news
 *
 * @param {string} href The link of the news page
 * @param {string} title
 * @param {string} date
 * @param {string} displayImage URL of the display image
 */
function News({ href, title, date, displayImage }: Props): JSX.Element {
  const dateFormat = dayjs(date).format('Do MMMM YYYY');
  const image = getImage(displayImage);

  return (
    <Wrapper data-cy="news-container">
      <div>
        <Link to={href} style={{ display: 'block', textDecoration: 'none' }}>
          <ImageWrapper>
            <GatsbyImage
              className="news-image"
              image={image as IGatsbyImageData}
              alt="news"
            />
          </ImageWrapper>
        </Link>
        <Title>{title}</Title>
      </div>
      <Date>{dateFormat}</Date>
    </Wrapper>
  );
}

export default News;
